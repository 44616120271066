<template>
    <div> 
        {{modele.name}}
    </div>
</template>

<script>
import {mapGetters} from 'vuex'
export default {
    props: {
        object: { type: Object, require: true }
    },
    data() {
        return {

        }
    },
    computed: {
        ...mapGetters({
            modeles: 'host/models'
        }),
        modele(){
            return [...this.modeles].find(item => item.uid === this.object.model)
        }
    }
}
</script>